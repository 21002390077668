import CustomModal from "../../../../global/components/CustomModal";
import {Col, Divider, Row} from "antd";
import {useState} from "react";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {VendorsURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {CustomPhoneNumberField} from "../../../../global/components/fields/CustomPhoneNumberField";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import {CustomSlugField} from "../../../../global/components/fields/CustomSlugField";
import Hide from "../../../../global/components/Hide";
import {VendorCategoryAutoComplete} from "../../../../global/components/fields/VendorCategoryAutoComplete";
import {CustomUploadField} from "../../../../global/components/fields/CustomUploadField";


export const VendorFormModal = props => {
    const [user_data, setUserData] = useState({});
    const [data, setData] = useState({
        is_active: true,
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;


        if (name === "logo") {
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }


        setData(temp_data);
    }


    const handleUserFieldChange = (name, value) => {
        let temp_user_data = {...user_data};
        temp_user_data[name] = value;
        setUserData(temp_user_data);

        if (['confirm_password', 'password'].includes(name)) {
            if (temp_user_data.confirm_password !== temp_user_data.password && isValidValue(temp_user_data.confirm_password)) {
                setHelperText({...helper_text, confirm_password: "Password does not match"})
            } else {
                setHelperText({...helper_text, confirm_password: undefined})
            }
        }
    }

    const onSave = async () => {
        await setLoading(true);

        const is_new = !isValidValue(data.id);
        let response;


        if (is_new && user_data.confirm_password !== user_data.password) {
            setHelperText({...helper_text, confirm_password: "Password does not match"})
            await setLoading(false);
            return
        }


        let temp_data = {
            ...data
        }


        if (is_new) {
            Object.keys(user_data).map(Name => {
                temp_data[`user_${Name}`] = user_data[Name];
            });
        }


        const form_data = new FormData();

        Object.keys(temp_data).map(Name=>{
            form_data.append(Name, temp_data[Name]);
        })

        if (is_new) {
            response = await httpRequestWithAuth(VendorsURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${VendorsURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Vendor", `${data.name} vendor has been added`)
            } else {
                openNotification("success", "Update Vendor", `${data.name} vendor has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Vendor Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Vendor Name"
                        name="name"
                        value={data.name}
                        onChange={handleFieldChange}
                        error={helper_text.name !== undefined}
                        helper_text={helper_text.name}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomSlugField
                        label="Slug"
                        name="slug"
                        value={data.slug}
                        onChange={handleFieldChange}
                        error={helper_text.slug !== undefined}
                        helper_text={helper_text.slug}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <VendorCategoryAutoComplete
                        required
                        multiple
                        label="Categories"
                        name="vendor_categories_id"
                        value={data.vendor_categories_id || []}
                        onChange={handleFieldChange}
                        error={helper_text.vendor_categories_id !== undefined}
                        helper_text={helper_text.vendor_categories_id}
                    />
                </Col>





                <Col xs={24} md={12}>
                    <CustomUploadField
                        display_value={data.logo_filename}
                        label="Logo"
                        name="logo"
                        value={data.logo}
                        onChange={handleFieldChange}
                        error={helper_text.logo !== undefined}
                        helper_text={helper_text.logo}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Vendor Contact E-mail"
                        name="vendor_contact_email"
                        value={data.vendor_contact_email}
                        onChange={handleFieldChange}
                        error={helper_text.vendor_contact_email !== undefined}
                        helper_text={helper_text.vendor_contact_email}
                        type="email"
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomPhoneNumberField
                        label="Vendor Contact No."
                        name="vendor_contact_number"
                        value={data.vendor_contact_number}
                        onChange={handleFieldChange}
                        error={helper_text.vendor_contact_number !== undefined}
                        helper_text={helper_text.vendor_contact_number}
                    />
                </Col>



                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="mt-3">
                    <Divider orientation="left" orientationMargin="0" className="fw-bold">
                        Vendor Pick Point
                    </Divider>
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="City"
                        name="city"
                        value={data.city}
                        onChange={handleFieldChange}
                        error={helper_text.city !== undefined}
                        helper_text={helper_text.city}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        required
                        label="ZIP/Post Code"
                        name="zip_code"
                        value={data.zip_code}
                        onChange={handleFieldChange}
                        error={helper_text.zip_code !== undefined}
                        helper_text={helper_text.zip_code}
                    />
                </Col>


                <Col xs={24}>
                    <CustomTextField
                        required
                        label="Address Line One"
                        name="address_line_one"
                        value={data.address_line_one}
                        onChange={handleFieldChange}
                        error={helper_text.address_line_one !== undefined}
                        helper_text={helper_text.address_line_one}
                    />
                </Col>


                <Col xs={24}>
                    <CustomTextField
                        label="Address Line Two"
                        name="address_line_one"
                        value={data.address_line_two}
                        onChange={handleFieldChange}
                        error={helper_text.address_line_two !== undefined}
                        helper_text={helper_text.address_line_two}
                    />
                </Col>

                <Col xs={24}>
                    <CustomTextField
                        label="Address Line Three"
                        name="address_line_three"
                        value={data.address_line_three}
                        onChange={handleFieldChange}
                        error={helper_text.address_line_three !== undefined}
                        helper_text={helper_text.address_line_three}
                    />
                </Col>


                <Hide hide={isValidValue(data.id)}>
                    <Col xs={24} className="mt-3">
                        <Divider orientation="left" orientationMargin="0" className="fw-bold">
                            Vendor Admin User Info
                        </Divider>
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="First Name"
                            name="first_name"
                            value={user_data.first_name}
                            onChange={handleUserFieldChange}
                            error={helper_text.first_name !== undefined}
                            helper_text={helper_text.first_name}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Last Name"
                            name="last_name"
                            value={user_data.last_name}
                            onChange={handleUserFieldChange}
                            error={helper_text.first_name !== undefined}
                            helper_text={helper_text.last_name}
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomPhoneNumberField
                            label="Contact No."
                            name="contact_number"
                            value={user_data.contact_number}
                            onChange={handleUserFieldChange}
                            error={helper_text.contact_number !== undefined}
                            helper_text={helper_text.contact_number}
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="E-mail"
                            name="email"
                            value={user_data.email}
                            onChange={handleUserFieldChange}
                            error={helper_text.email !== undefined}
                            helper_text={helper_text.email}
                            type="email"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Password"
                            name="password"
                            value={user_data.password}
                            onChange={handleUserFieldChange}
                            error={helper_text.password !== undefined}
                            helper_text={helper_text.password}
                            type="password"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Confirm Password"
                            name="confirm_password"
                            value={user_data.confirm_password}
                            onChange={handleUserFieldChange}
                            error={helper_text.confirm_password !== undefined}
                            helper_text={helper_text.confirm_password}
                            type="password"
                        />
                    </Col>
                </Hide>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
