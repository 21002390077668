import React from "react";
import { Alert, Button, Col, Row } from "antd";
import { isValidValue } from "../helper_functions";
import { CheckRound, Close, Trash } from "@rsuite/icons";
import Hide from "./Hide";

export const ActionButton = (props) => {
  return (
    <>
      <Row className="mt-3">
        <Hide hide={!isValidValue(props.error_msg)}>
          <Col xs={24} className="mb-2">
            <Alert
              className="text-danger"
              banner
              description={props.error_msg}
              type="error"
              showIcon={props.showIcon}
            />
          </Col>
        </Hide>

        <Col span={24} className="d-inline-flex mb-3">
          <Hide hide={!props.allow_delete}>
            <Button danger type="primary" onClick={props.onDelete} className=" font-size-14">
              <Hide hide_down_md>
              <Trash className="me-1 d-none d-md-inline" />
              </Hide>
              Delete
            </Button>
          </Hide>

          <div className="ms-auto">
            <Button onClick={props.onCancel} className="me-2  font-size-14">
              <Hide hide_down_md>
              <Close className="me-1" />
              </Hide>

              {props.cancelLabel? props.cancelLabel:props.allow_save ? "Cancel" : "Close"}
            </Button>

            <Hide hide={!props.allow_save}>
              <Button
                type="primary"
                onClick={props.onSave}
                className="font-size-14"
              >
                <Hide hide_down_md>
                <CheckRound className="mb-1 me-2"/>
                </Hide>

                {props.saveLabel ? props.saveLabel : "Save"}
              </Button>
            </Hide>
          </div>
        </Col>
      </Row>
    </>
  );
};
