import React, {useEffect, useState} from 'react';
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {vendorSignIn, isAuthenticated} from "../../global/auth_functions";
import Bg from "../../assets/img/vendor_sigin_bg.jpg";
import Logo from "../../assets/img/logo.png";
import {Button, Col, Row} from "antd";
import {useParams} from "react-router-dom";
import Cookies from "js-cookie";

export const VendorSignin = props => {
    const params = useParams();
    const slug = params.slug || ""
    const [data, setData] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const vendor_name = Cookies.get("vendor_name");

    useEffect(() => {
        const checkAuth = async () => {
            const is_auth = await isAuthenticated();
            if (is_auth) {
                props.history.push(`/${slug}/dashboard`);
            }
        }

        checkAuth();
        document.title = "Sigin";
    }, [])


    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value});
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        await setIsLoading(true);

        let response = await vendorSignIn(data.email, data.password, slug);

        if (response.status) {
            props.history.push(`/${slug}/dashboard`);
        } else {
            setHelperText(response.error_feedback)
        }

        await setIsLoading(false);
    }


    const forgetPassword = () =>{
        props.history.push(`/${slug}/forget-password`);
    }


    return (
        <main className="vh-100 overflow-hidden">
        <Row>
            <Col xs={24} sm={15} md={10} lg={7} className="text-center p-4 pt-5">

                <Col xs={24} className="mt-5 pt-5">
                    {/*<img src={Logo} className="w-100"/>*/}
                    <h1 className="font-size-22">{vendor_name}</h1>
                </Col>

                <span className="text-danger mt-2">
                            {helper_text.non_field_errors}
                </span>

                <form className="w-100" onSubmit={onSubmit}>


                    <Row gutter={[12, 12]}>

                        <Col xs={24} className="d-flex justify-content-center">

                            <Col xs={12} sm={11} md={12} lg={8}>
                                <img src={Logo} className="w-100"/>
                            </Col>
                        </Col>


                        <Col xs={24}>
                            <CustomTextField
                                label="Email"
                                name="email"
                                value={data.email}
                                onChange={handleFieldChange}
                                helper_text={helper_text.email}
                                className="mt-2"
                            />
                        </Col>


                        <Col xs={24}>
                            <CustomTextField
                                label="Password"
                                name="password"
                                value={data.password}
                                onChange={handleFieldChange}
                                helper_text={helper_text.password}
                                type="password"
                                autoComplete="current-password"
                                className="mt-3"
                            />
                        </Col>

                    </Row>


                    <Button
                        block
                        loading={is_loading}
                        disabled={is_loading}
                        onClick={onSubmit}
                        type="primary"
                        className="mt-4"
                    >
                        Sign In
                    </Button>


                    <Button
                        onClick={forgetPassword}
                        type="link"
                        block
                        className="text-primary text-end mt-4"
                    >
                        Forget password?
                    </Button>


                </form>
            </Col>

            <Col xs={0} sm={9} md={14} lg={17} className="bg-white">
                <img src={Bg} className="w-100 vh-100 position-absolute "/>
            </Col>
        </Row>
        </main>

    );
}

