import {Button, Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {VendorsBrandURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {VendorBrandFormModal} from "./components/VendorBrandFormModal";
import {IsActiveView} from "../../../global/components/IsActiveView";

export const VendorBrands = props => {
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [vendor_brand_perm] = useState({
        delete: checkPermission(permission_data.vendor_brand.delete),
        edit: checkPermission(permission_data.vendor_brand.edit),
        add: checkPermission(permission_data.vendor_brand.add),
    });

    const [columns] = useState([
        {
            title: "Logo",
            dataIndex: "display_logo",
            width: "20%",
            align: "center",
            render:(value)=>(
                <Image src={value} width="100%"/>
            )
        },
        {
            title: "Vendor",
            dataIndex: "vendor_name",
            width: "20%",
        },
        {
            title: "Name En",
            dataIndex: "name_en",
            width: "20%",
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "20%",
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!vendor_brand_perm.edit}/>
        },
    ])

    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }


    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${VendorsBrandURL}${row_data.id}`, "DELETE");

        if (response.status) {
            openNotification("success", "Delete Vendor Brand", `${row_data.name_en} brand has been deleted`)
        } else {
            openNotification("error", "Delete Vendor Brand", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Vendor Brand</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <VendorBrandFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Vendors Brands"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={VendorsBrandURL}
                    free_action={vendor_brand_perm.add && freeAction}
                    onDelete={vendor_brand_perm.delete && onDelete}
                    onEdit={vendor_brand_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
