import DynamicAutocomplete from "./DynamicAutocomplete";
import {ListProductsCategoriesURL} from "../../urls";

export const ProductCategoriesAutoComplete = (props)=>{
    const params = {vendor: props.vendor || "", vendor_category: props.vendor_category || ""};
    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={ListProductsCategoriesURL}
            title_key="name_en"
            mode={props.multiple ? "multiple":""}
            params_array={[props.vendor, props.vendor_category]}
            params={params}
        />
    )
}
