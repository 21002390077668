import React, {useEffect, useState} from 'react';
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import Logo from "../../assets/img/logo.png";
import Bg from "../../assets/img/vendor_sigin_bg.jpg";
import {Button, Col, Row} from "antd";
import {httpRequestWithoutAuth} from "../../global/axios_instance";
import {UserRestPasswordURL, BaseBackEndAPIsURL} from "../../global/urls";
import {showInfo} from "../../global/helper_functions";


export const RestPassword = props => {
    const url_query = new URLSearchParams(props.location.search);
    const token = url_query.get("token") || "";

    const [data, setData] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);


    useEffect(() => {
        document.title = "Forget Password";
    }, [])


    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value});
        setHelperText({});
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        await setIsLoading(true);

        let response = await httpRequestWithoutAuth(`${BaseBackEndAPIsURL}${UserRestPasswordURL}`, "POST", {...data, token})

        if (response.status) {
            showInfo("Rest Password", "Your password has been rest successfully", () => {
                props.history.push(`/${response.data.slug}/signin`);
            });
        } else {
            setHelperText(response.error_feedback)
        }

        await setIsLoading(false);
    }


    return (
        <main className="vh-100 overflow-hidden bg-black">
            <Row className="d-flex justify-content-center align-items-start pt-5 mt-5">
                <Col xs={20} sm={16} md={10} lg={8}
                     className="shadow border-radius-10 bg-white d-flex flex-column align-items-center p-4 mt-5 ">

                    <Col xs={21} sm={18} md={16} lg={8}>
                        <img src={Logo} className="w-100"/>
                    </Col>

                    <span className="text-danger mt-2">
                            {helper_text.non_field_errors}
                </span>

                    <form className="w-100" onSubmit={onSubmit}>


                        <Row gutter={[12, 12]}>

                            <Col xs={24}>
                                <CustomTextField
                                    label="New Password"
                                    name="new_password"
                                    value={data.new_password}
                                    onChange={handleFieldChange}
                                    helper_text={helper_text.new_password}
                                    error={helper_text.new_password !== undefined}
                                    className="mt-2"
                                    type="password"
                                />
                            </Col>

                            <Col xs={24}>
                                <CustomTextField
                                    label="Confirm New Password"
                                    name="confirm_new_password"
                                    value={data.confirm_new_password}
                                    onChange={handleFieldChange}
                                    helper_text={helper_text.confirm_new_password}
                                    error={helper_text.confirm_new_password !== undefined}
                                    className="mt-2"
                                    type="password"
                                />
                            </Col>
                        </Row>


                        <Button
                            block
                            loading={is_loading}
                            disabled={is_loading}
                            onClick={onSubmit}
                            type="primary"
                            className="bg-black mt-4"
                        >
                            Submit
                        </Button>
                    </form>
                </Col>
            </Row>
        </main>

    );
}

