import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import Hide from "../../../global/components/Hide";
import {CustomTabPane} from "../../../global/components/CustomTabPane";
import {getUserType} from "../../../global/auth_functions";
import {ProductDetails} from "./ProductDetails";
import ProductSizes from "./ProductSizes";
import {checkPermission} from "../../../global/permissions";
import {permission_data} from "../../../global/permissions_data";


export const ProductInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const url_query = new URLSearchParams(props.location.search);

    const [product_id] = React.useState(url_params.id || 'new');
    const tab = (url_query.get("tab") || "1") + "";

    const [is_new, setIsNew] = useState(product_id === "new");
    const [user_type] = useState(getUserType());
    const is_admin = user_type === "Admin";
    const is_vendor = user_type === "Vendor";

    const [product_data, setProductData] = useState("en");
    const [current_tab, setCurrentTab] = useState("1");

    const handleTabsChange = (new_value, extra_params={}) => {
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        setCurrentTab(new_value);
    };




    return (
        <>
            <div className="m-0 p-0 d-flex justify-content-between">
                <h5 className="mb-3 mt-2">{product_id === "new" ? "Add Product" :  (<>Edit Product > <span className="text-black-50">{product_data.name_en}</span></>)}</h5>
            </div>


            <div className="paper pt-0 w-100">

                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Sizes" key="2"
                                   disabled={is_new || !checkPermission(permission_data.product_size.view) || !product_data.has_sizes}/>
                </Tabs>

                <Hide hide={current_tab !== "1"}>
                <ProductDetails
                    {...props}
                    is_vendor={is_vendor}
                    is_new={is_new}
                    user_type={user_type}
                    is_admin={is_admin}
                    product_id={product_id}
                    setProductData={setProductData}
                />
                </Hide>

                <Hide hide={current_tab !== "2"}>
                <ProductSizes
                    {...props}
                    is_vendor={is_vendor}
                    is_new={is_new}
                    user_type={user_type}
                    is_admin={is_admin}
                    product_id={product_id}
                    setProductData={setProductData}
                />
                </Hide>



            </div>

        </>)
}
