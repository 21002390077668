import React from "react";
import {Spin} from "antd";

export const Loading = props =>{
    return(
        <div className="w-100 text-center mt-4 pt-4 pb-2 mb-3">
            <Spin size="large" />
            <h1 className="mt-2">{props.msg || "loading...."}</h1>
        </div>
    )
}