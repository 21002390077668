import {Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {ProductsURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {IsActiveView} from "../../../global/components/IsActiveView";
import Hide from "../../../global/components/Hide";
import {isVendor} from "../../../global/auth_functions";
import Cookies from "js-cookie";

export const Products = props =>{
    const {slug} = props;
    const [reload, setReload] = useState(false);
    const is_vendor = isVendor();

    const [product_perm] = useState({
        delete: checkPermission(permission_data.product.delete),
        edit: checkPermission(permission_data.product.edit),
        add: checkPermission(permission_data.product.add),
    });

    const [columns] = useState([
        {
            title: "Image",
            dataIndex: "display_image_1",
            width: "15%",
            align: "center",
            render:(value)=>(
                <Image src={value} width={120}/>
            )
        },
        {
            title: "Name En",
            dataIndex: "name_en",
            width: "35%"
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "35%"
        },

        {
            title: "Vendor",
            dataIndex: "vendor_name",
            width: "20%",
            hide: is_vendor
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "5%",
            align:"center",
        },
        {
            title: "Price",
            dataIndex: "price",
            width: "10%",
            align:"center",
            render: (value)=>value + " KWD"
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={!product_perm.edit}/>
        },

    ]);

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${ProductsURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Product", `${row_data.name_en} product has been deleted`)
        }else{
            openNotification("error", "Delete Product", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = (row_data, current_page) =>{
        Cookies.set("products_open_in_page", current_page, {expires: 1/48/6});

        props.history.push(`/${slug}/products/${row_data.id}`)
    }

    const onAdd = async () =>{
        props.history.push(`/${slug}/products/new`)
    }

    const freeAction = (
        <Hide hide={!product_perm.add}>
            <AddButton onClick={onAdd}>Add Product</AddButton>
        </Hide>
    );

    return(
        <Row>
            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Products"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ProductsURL}
                    free_action={product_perm.add && freeAction}
                    onDelete={product_perm.delete && onDelete}
                    onEdit={product_perm.edit && onEdit}
                    open_in_page={Cookies.get("products_open_in_page")}
                />
            </Col>
        </Row>
    )
}
