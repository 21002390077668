import React, {useEffect, useState} from "react";
import {logout} from "../global/auth_functions";
import {Loading} from "../global/components/Loading";
import {Redirect, Route, Switch} from "react-router-dom";
import {AdminSignIn} from "../pages/auth/AdminSignin";
import {VendorSignin} from "../pages/auth/VendorSignin";
import {httpRequestWithoutAuth} from "../global/axios_instance";
import {VendorsGetBySlugURL, BaseBackEndAPIsURL} from "../global/urls";
import Cookies from 'js-cookie'
import {isValidValue} from "../global/helper_functions";
import {ProtectedApp} from "./ProtectedApp";
import {AdminForgetPassword} from "../pages/auth/AdminForgetPassword";
import {VendorForgetPassword} from "../pages/auth/VendorForgetPassword";

export const SlugRoute = props => {
    const url_params = props.match.params || {};
    const slug = (url_params.slug || "").toLowerCase();

    const [is_loading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkSlug = async () => {
            // check slug from backend
            if (slug === "admin"){
                Cookies.set("slug", slug);
            }else{
                let response = await httpRequestWithoutAuth(`${BaseBackEndAPIsURL}${VendorsGetBySlugURL}?slug=${slug}`, "GET");
                if (response.status){
                    Cookies.set("slug", slug);
                    Cookies.set("vendor_name", response.data.name);
                }else{
                    logout();
                    let old_slug = Cookies.get("slug");
                    if (isValidValue(old_slug)){
                        await setIsLoading(false);
                        props.history.push(`/${old_slug}/signin`)
                    }else{
                        await setIsLoading(false);
                        props.history.push(`/welcome`)
                    }
                    Cookies.clear();
                }
            }
            await setIsLoading(false);
        }

        checkSlug();
    }, [slug])

    if (is_loading) {
        return <Loading full_page/>
    }

    return (
        <Switch>
            <Route path="/admin/forget-password" exact component={AdminForgetPassword}/>
            <Route path="/:slug/forget-password" exact component={VendorForgetPassword}/>
            <Route path="/admin/signin" exact component={AdminSignIn}/>
            <Route path="/:slug/signin" exact component={VendorSignin}/>
            <Route path="/:slug" exact render={() => <Redirect to={`/${slug}/dashboard`}/>}/>
            <Route path="/:slug" component={ProtectedApp}/>

        </Switch>
    )
}
