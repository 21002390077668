import React, {useState} from "react";
import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {BlogsURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification, showInfo} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {IsActiveView} from "../../global/components/IsActiveView";
import {EyeOutlined} from "@ant-design/icons";

export const Blogs = props => {
    const {slug} = props;
    const [blog_perm] = useState({
        delete: checkPermission(permission_data.blog.delete),
        edit: checkPermission(permission_data.blog.edit),
        add: checkPermission(permission_data.blog.add),
    });
    const [reload_data, setReloadData] = useState(false);

    const [columns] = useState([
        {
            title: "Ref No.",
            dataIndex: "ref_no",
            width: "10%",
            render: (value, row_data) => <a href={`/${slug}/blogs/${row_data.id}`} target="_blank">{value}</a>
        },
        {
            title: "Title",
            dataIndex: "title_en",
            width: "60%"
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "10%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={blog_perm.edit}/>
        },
    ])


    const reloadData = () =>{
        setReloadData(Math.random());
    }


    const changeActivateStatus = async (row_data) =>{
        const response = await httpRequestWithAuth(`${BlogsURL}${row_data.id}`, "PATCH", {is_active: !row_data.is_active});
        if (response.status){
            reloadData();
            if (response.data.pending_change){
                showInfo("Update Activate Status", response.data.msg);
            }else{
                openNotification("success", "Update Activate Status", response.data.msg || `${row_data.title_en} blog has been updated`)
            }
        }else{
            openNotification("error", "Update Activate Status", response.error_feedback.non_field_errors)
        }
    }

    const extra_actions = [
        {
            key: "view-blog-in-customer-website",
            icon: EyeOutlined,
            render: (row_data)=><a href={row_data.customer_website_url} target="_blank">View In Website</a>,
        },
        {
            key: "change-active-to-inactive",
            icon: EyeOutlined,
            onClick: changeActivateStatus,
            render: (row_data)=> <span className="text-danger">Deactivate Blog</span>,
            hide: (row_data)=>!row_data.is_active,
            iconClassName: "text-danger"
        },
        {
            key: "change-inactive-to-active",
            icon: EyeOutlined,
            onClick: changeActivateStatus,
            render: (row_data)=> <span className="main-color">Activate Blog</span>,
            hide: (row_data)=>row_data.is_active,
            iconClassName: "main-color"
        },
    ]



    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${BlogsURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Blog", `${row_data.title_en} blog has been deleted`)
        } else {
            openNotification("error", "Delete Blog", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        props.history.push(`/${slug}/blogs/${row_data.id}`);
    }

    const onAdd = async () => {
        props.history.push(`/${slug}/blogs/new`);
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Blog</AddButton>
        </>
    );

    return (
        <Row>
            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Blog Articles"
                    columns={columns}
                    fetch_url={BlogsURL}
                    free_action={freeAction}
                    extra_actions={extra_actions}
                    reload_data={reload_data}
                    onDelete={blog_perm.delete && onDelete}
                    onEdit={blog_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
