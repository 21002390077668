import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {ProductSizesURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";
import ProductSizeFormModal from "./components/ProductSizeFormModal";

const ProductSizes = props => {
    const {product_id} = props;
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [product_size_perm] = useState({
        delete: checkPermission(permission_data.product_size.delete),
        edit: checkPermission(permission_data.product_size.edit),
        add: checkPermission(permission_data.product_size.add),
    });

    const [columns] = useState([
        {
            title: "Name En",
            dataIndex: "name_en",
            width: "30%",
        },
        {
            title: "Name Ar",
            dataIndex: "name_ar",
            width: "30%",
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "18%",
            align: "center",
        },
        {
            title: "Order",
            dataIndex: "order",
            width: "18%",
            align: "center",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            width: "5%",
            align: "center",
            render: (value) => <IsActiveView value={value} view_only/>
        },

    ]);



    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }


    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${ProductSizesURL}${row_data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Product Size", `product size has been deleted`)
        } else {
            openNotification("error", "Delete Product Size", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Product Size</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <ProductSizeFormModal
                    open={open_from_data}
                    data={{ProductId: product_id, ...form_data}}
                    handleModelClose={handelCloseFormData}
                    product_id={product_id}
                />
            </Hide>

            <Col xs={24}>
                <CustomDynamicTable
                    title="Product Sizes"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={ProductSizesURL}
                    free_action={freeAction}
                    onDelete={product_size_perm.delete && onDelete}
                    onEdit={product_size_perm.edit && onEdit}
                    custom_params={{product: product_id}}
                />
            </Col>
        </Row>
    )
}

export default ProductSizes;
