import React from "react";

export const InfoIconWashedGreenOutline = (props) => {
  return (
    <img
      src={require("../../assets/icon/INFO_ICON_2G.png")}
      style={{ width: props.width || 22 }}
    />
  );
};

export const InfoIconWashedGreenFilled = (props) => {
  return (
    <img
      src={require("../../assets/icon/INFO_ICON_1G.png")}
      style={{ width: props.width || 22 }}
    />
  );
};

export const InfoIconGreenOutline = (props) => {
  return (
    <img
      src={require("../../assets/icon/INFO_ICON_2GG.png")}
      style={{ width: props.width || 22 }}
    />
  );
};

export const InfoIconGreenFilled = (props) => {
  return (
    <img
      src={require("../../assets/icon/INFO_ICON_1GG.png")}
      style={{ width: props.width || 22 }}
    />
  );
};

export const InfoIconWarningFilled = (props) => {
  return (
    <img
      src={require("../../assets/icon/INFO_ICON_1OR.png")}
      style={{ width: props.width || 15 }}
    />
  );
};

export const InfoIconGreyFilled = (props) => {
  return (
    <img
      src={require("../../assets/icon/INFO_ICON_1B.png")}
      style={{ width: props.width || 22 }}
    />
  );
};

export const InfoIconGreyOutline = (props) => {
  return (
    <img
      src={require("../../assets/icon/INFO_ICON_2B.png")}
      style={{ width: props.width || 22 }}
    />
  );
};
