import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {useState} from "react";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {ProductSizesURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";

const ProductSizeFormModal = props => {
    const [data, setData] = useState({
        is_active: true,
        qty: 0,
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;
        setData(temp_data);
    }


    const onSave = async () => {
        await setLoading(true);

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(ProductSizesURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${ProductSizesURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Product Size", `${data.name_en} has been added`)
            } else {
                openNotification("success", "Product Size", `${data.name_en} has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="md" title="Product Size Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24} md={8}>
                    <CustomTextField
                        label="Name En"
                        name="name_en"
                        value={data.name_en}
                        onChange={handleFieldChange}
                        error={helper_text.name_en !== undefined}
                        helper_text={helper_text.name_en}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        label="Name Ar"
                        name="name_ar"
                        value={data.name_ar}
                        onChange={handleFieldChange}
                        error={helper_text.name_ar !== undefined}
                        helper_text={helper_text.name_ar}
                    />
                </Col>


                <Col xs={24} md={4}>
                    <CustomTextField
                        label="Qty"
                        name="qty"
                        value={data.qty}
                        onChange={handleFieldChange}
                        error={helper_text.qty !== undefined}
                        helper_text={helper_text.qty}
                        type="number"
                    />
                </Col>

                <Col xs={24} md={4}>
                    <CustomTextField
                        label="Order"
                        name="order"
                        value={data.order}
                        onChange={handleFieldChange}
                        error={helper_text.order !== undefined}
                        helper_text={helper_text.order}
                        type="number"
                    />
                </Col>




                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>


                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}


export default ProductSizeFormModal;
