import React from "react";
import {PRIMARY_COLOR} from "../../global/variables";
import {Col, Row} from "antd";
import {getUserName} from "../../global/auth_functions";
import Logo from "../../assets/img/logo.png";

const style = {
    line_one_container: {
        borderBottomWidth: "10px",
        borderBottomColor: PRIMARY_COLOR,
        borderBottomStyle: "solid",
        borderEndEndRadius: "10px",
        borderEndStartRadius: "10px",
        height: "110px",
    }
}

export const Dashboard = props => {
    return (
            <Row gutter={[12,12]}>

            <Col xs={24} className="text-center mt-5">
                <h1 className="font-size-22">Hi {getUserName()},</h1>
                <h1 className="font-size-22">Welcome to Gallerias app control panel</h1>
                <img src={Logo} className="w-75" style={{maxWidth: 500}}/>
            </Col>


            </Row>
    )
}
