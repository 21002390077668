import React from "react";
import { Checkbox, Form, Radio } from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";

const CustomCheckBox = (props) => {
  const handleFieldChange = (e) => {
    props.onChange(props.name, e.target.checked);
  };

  return (
    <CustomFieldWrapper {...props} label={null} tooltip={null} container_class_name="h-100">
      <Checkbox
        {...props}
        name={props.name}
        onChange={handleFieldChange}
        checked={props.value}

      >
          {props.label}
      </Checkbox>
    </CustomFieldWrapper>
  );
};

export default CustomCheckBox;
