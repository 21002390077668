import React, {useEffect, useState} from "react";
import { Input, Select } from "antd";
import { PHONE_CODES } from "../../variables";
import CustomFieldWrapper from "../CustomFieldWrapper";
const { Option } = Select;


export const CustomPhoneNumberField = (props) => {
    const [first_render, setFirstRender] = useState(true);
    const [phone_number, setPhoneNumber] = useState("");
    const [phone_number_code, setPhoneNumberCode] = useState("+965");

  const handlePhoneNumberChange = (e) => {
    if (!props.view_only && !props.disabled) {
      setPhoneNumber(e.target.value)
    }
  };

    const handlePhoneNumberCodeChange = (code) => {
        if (!props.view_only && !props.disabled) {
            setPhoneNumberCode(code)
        }
    };

    useEffect(()=>{
        const value_data = (props.value || "").split('-');
        if (value_data.length === 2){
            setPhoneNumberCode(value_data[0])
            setPhoneNumber(value_data[1])
        }
    },[props.value])


    useEffect(()=>{
        if (first_render){
            setFirstRender(false);
        }else{
            if (props.onChange){
                props.onChange(props.name, `${phone_number_code}-${phone_number}`);
            }
        }

    }, [phone_number, phone_number_code])


    const CountryOptions = PHONE_CODES.map((country) => (
    <Option key={country.code} title={country.name} value={country.phone}>
      {country.phone + "-" + country.code}
    </Option>
  ));

  const selectBefore = (
    <Select
        disabled={props.disabled || props.view_only}
      getPopupContainer={(trigger) => trigger.parentNode}
      style={{ width: 110 }}
      showSearch
      value={phone_number_code}
      optionFilterProp="children"
      filterOption={(input, option) => {
        return (
          option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      onChange={handlePhoneNumberCodeChange}
    >
      {CountryOptions}
    </Select>
  );

  return (
    <CustomFieldWrapper {...props}>
      <Input
          disabled={props.disabled || props.view_only}
          placeholder={props.placeholder}
        addonBefore={selectBefore}
        onChange={handlePhoneNumberChange}
        value={phone_number}
        name={props.name}
        status={props.error ? "error" : null}
        type="number"
        className={`mt-0 ${!props.disabled && props.view_only?"bg-white text-black":""} ${props.inputClassName}`}
      />
    </CustomFieldWrapper>
  );
};
