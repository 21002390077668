import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {UsersURL} from "../../global/urls";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {openNotification, viewDateAndTime} from "../../global/helper_functions";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {AddButton} from "../../global/components/AddButton";
import {UserFormModal} from "./components/UserFormModal";
import Hide from "../../global/components/Hide";
import CustomBadge from "../../global/components/CustomBadge";
import {IsActiveView} from "../../global/components/IsActiveView";
import {getUserType, isAdmin} from "../../global/auth_functions";
import CustomSelectField from "../../global/components/fields/CustomSelectField";



const user_type_options = [
    {title: "Admin", value: "Admin"},
    {title: "Vendor", value: "Vendor"},
    {title: "Customer", value: "Customer"},
];

export const Users = props =>{
    const [is_admin] = useState(isAdmin());
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);
    const [user_type] = useState(getUserType());
    const [filter, setFilter] = useState({});

    const [user_perm] = useState({
        delete: checkPermission(permission_data.user.delete),
        edit: checkPermission(permission_data.user.edit),
        add: checkPermission(permission_data.user.add),
        change_status: checkPermission(permission_data.user.change_status),
        export_user: checkPermission(permission_data.user.export_user),
    });

    const [columns] = useState([

        {
            title: "Name",
            dataIndex: "full_name",
            width: "40%"
        },
        {
            title: "E-mail",
            dataIndex: "email",
            width: "30%"
        },
        {
            title: "Contact No.",
            dataIndex: "contact_number",
            width: "15%"
        },

        {
            title: "Type",
            dataIndex: "user_type",
            hide: user_type === "Vendor",
            width: "15%",
            render:(value, row_data)=>(
                <>
                    <Hide hide={row_data.is_vendor_admin}>
                        {value}
                    </Hide>

                    <Hide hide={!row_data.is_vendor_admin}>
                        <CustomBadge title={"Vendor Admin"}/>
                    </Hide>
                </>
            )
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "20%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={user_perm.edit}/>
        },

    ])


    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${UsersURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete User", `${row_data.full_name} user has been deleted`)
        }else{
            openNotification("error", "Delete User", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add User</AddButton>
        </>
    );


    const handleFilterChange = (name, value) =>{
        setFilter({
            ...filter,
            [name]: value
        })
        setReload(Math.random());
    }

    const filter_toolbar = (
        <Row gutter={[10,10]}>
            <Col xs={24} md={8}>
                <CustomSelectField
                    empty_title="All"
                    label="User Type"
                    name="user_type"
                    value={filter.user_type}
                    onChange={handleFilterChange}
                    options={user_type_options}
                />
            </Col>

        </Row>
    );


    const export_columns = [
        {
            title: "Full Name",
            key: "full_name",
        },
        {
            title: "Email",
            key: "email",
        },
        {
            title: "Contact No.",
            key: "contact_number",
        },
        {
            title: "Join Date",
            key: "created_at_view",
        },
    ]

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <UserFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Users"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={UsersURL}
                    free_action={user_perm.add && freeAction}
                    filter_toolbar={is_admin && filter_toolbar}
                    // onDelete={user_perm.delete && onDelete}
                    onEdit={user_perm.edit && onEdit}
                    custom_params={filter}
                    export_columns={export_columns}
                    allow_export={user_perm.export_user}
                />
            </Col>
        </Row>
    )
}
