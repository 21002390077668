import React from "react";

// import {Button, CloseButton} from "react-bootstrap";
import {Button, Col, Image} from "antd";
import {isValidValue} from "../helper_functions";
import CustomFileUpload from "./CustomFileUpload";
import {useEffect} from "react";


export const ImageUploaderWithView = (props) => {
    const avatar_shape = props.avatar_shape || false;
    const maxWidth = props.maxWidth || 450
    const handleImageChange = (value_name, new_value) => {
        props.onChange(props.name, new_value);
    };


    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            {isValidValue(props.display_image) && (
                <div className="d-flex justify-content-center align-items-center m-0">
                    <div style={{maxWidth}}>
                        {/*<div className="position-absolute w-100 h-100 z-index-100">*/}

                        {/*</div>*/}

                        <Col xs={24} className="text-center w-100 p-0">
                            <Image
                                src={props.display_image}
                                className="h-100 w-100"
                                alt=""
                            />
                        </Col>

                        <Button
                            onClick={() => props.onChange(props.name, "")}
                            size="sm"
                            danger
                            variant="danger"
                            type="link"
                            className="w-100 text-end p-0 m-0"
                        >
                            {/*<CloseButton/>*/}
                            Remove
                        </Button>
                    </div>
                </div>
            )}

            {!isValidValue(props.display_image) && (
                <CustomFileUpload
                    hide_remove
                    avatar_shape={avatar_shape}
                    name={props.name}
                    value={props.value}
                    onChange={handleImageChange}
                    label={props.label}
                    helper_text={props.helper_text}
                    error={props.error}
                    accept="image/*"
                    maxWidth={maxWidth}
                />
                )}

        </div>
    );
};
