import React, {useEffect, useState} from 'react';
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {adminSignIn, isAuthenticated} from "../../global/auth_functions";
import Logo from "../../assets/img/logo.png";
import {Button, Col, Row} from "antd";
import Hide from "../../global/components/Hide";


export const AdminSignIn = props => {
    const [data, setData] = useState({});
    const [helper_text, setHelperText] = useState({});
    const [is_loading, setIsLoading] = useState(false);
    const [need_verification_code, setNeedVerificationCode] = useState(false);


    useEffect(() => {
        const checkAuth = async () => {
            const is_auth = await isAuthenticated();
            if (is_auth) {
                props.history.push(`/admin/dashboard`);
            }
        }

        checkAuth();
        document.title = "Sigin";
    }, [])


    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value});
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        await setIsLoading(true);

        let response = await adminSignIn(data.email, data.password, {
            check_code: need_verification_code,
            verification_code: data.verification_code
        });

        if (response.status) {
            props.history.push("/admin/dashboard");
        } else {
            if (response.error_feedback.need_verification_code){
                setNeedVerificationCode(true)
            }else{
                setHelperText(response.error_feedback)
            }
        }

        await setIsLoading(false);
    }

    const forgetPassword = () =>{
        props.history.push("/admin/forget-password")
    }


    return (
        <main className="vh-100 overflow-hidden bg-black">
        <Row className="d-flex justify-content-center align-items-start pt-5 mt-5">
            <Col xs={22} sm={16} md={10} lg={6}
                 className="shadow border-radius-10 bg-white d-flex flex-column align-items-center p-3 mt-5 ">

                <Col xs={14} sm={11} md={12} lg={10}>
                    <img src={Logo} className="w-100"/>
                </Col>

                <span className="text-danger mt-2">
                            {helper_text.non_field_errors}
                </span>

                <form className="w-100" onSubmit={onSubmit}>


                    <Row gutter={[12, 12]}>

                        <Hide hide={!need_verification_code}>
                            <Col xs={24}>
                                <CustomTextField
                                    label="Verification Code"
                                    name="verification_code"
                                    value={data.verification_code}
                                    onChange={handleFieldChange}
                                    helper_text={helper_text.verification_code}
                                    className="mt-2"
                                />
                            </Col>
                        </Hide>

                        <Hide hide={need_verification_code}>
                        <Col xs={24}>
                            <CustomTextField
                                label="Email"
                                name="email"
                                value={data.email}
                                onChange={handleFieldChange}
                                helper_text={helper_text.email}
                                className="mt-2"
                            />
                        </Col>


                        <Col xs={24}>
                            <CustomTextField
                                label="Password"
                                name="password"
                                value={data.password}
                                onChange={handleFieldChange}
                                helper_text={helper_text.password}
                                type="password"
                                autoComplete="current-password"
                                className="mt-3"
                            />
                        </Col>
                        </Hide>

                    </Row>


                    <Button
                        block
                        loading={is_loading}
                        disabled={is_loading}
                        onClick={onSubmit}
                        type="primary"
                        className="mt-4"
                    >
                        {need_verification_code ?"Submit":"Sign In"}
                    </Button>


                    <Hide hide={need_verification_code}>
                        <Button
                            onClick={forgetPassword}
                            type="link"
                            block
                            className="text-primary text-end mt-2"
                        >
                            Forget password?
                        </Button>
                    </Hide>

                </form>
            </Col>
        </Row>
        </main>

    );
}

