import React, {useCallback, useEffect, useState} from "react";
import CustomModal from "./CustomModal";
import {Col, Row, Slider, Typography} from "antd";
import {ModelActionButton} from "./ModelActionButton";
import Cropper from "react-easy-crop";



export const ImageCropModal = props => {
    const aspect = props.aspect || 1
    const crop_height = props.crop_height || 300
    const [crop_image_src, setCropImageSrc] = React.useState(null)
    const [zoom, setZoom] = useState(1)
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    useEffect(()=>{
        const getImage = async () => {
          setCropImageSrc(await readFile(props.image))
        }

        getImage()
    })
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])


    const onSaveCrop = async () => {
        const croppedImage = await getCroppedImg(
            crop_image_src,
            croppedAreaPixels,
        )


        setCropImageSrc(null)
        props.onSave(croppedImage);
    }


    return (
        <CustomModal title="Image Crop" visible={props.open} onCancel={props.handleModelClose} size="sm">
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <div className="w-100 position-relative" style={{height: crop_height}}>
                        <Cropper
                            image={crop_image_src}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div>
                        <div>
                            <Typography
                                variant="overline"
                            >
                                Zoom
                            </Typography>
                            <Slider
                                value={zoom}
                                min={1}
                                max={5}
                                step={0.1}
                                onChange={setZoom}
                            />
                        </div>
                    </div>
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        onSave={onSaveCrop}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}




function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

export const createImage = (url) =>{
    const image = document.createElement('img')
    image.src = url
    return image;
}

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            resolve(file)
        }, 'image/png')
    })
}
