import {Col, Row} from "antd";
import {CustomDynamicTable} from "../../global/components/CustomDynamicTable";
import {useState} from "react";
import {OrdersURL} from "../../global/urls";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {isAdmin} from "../../global/auth_functions";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {delivery_by_options, order_status_options} from "../../global/variables";
import {CustomDateField} from "../../global/components/fields/CustomDateField";
import {viewDateAndTime} from "../../global/helper_functions";

export const Orders = props => {
    const {slug} = props;
    const is_admin = isAdmin()
    const [reload, setReload] = useState(false);
    const [filter, setFilter] = useState({});

    const [order_perm] = useState({
        delete: checkPermission(permission_data.order.delete),
        edit: checkPermission(permission_data.order.edit),
        add: checkPermission(permission_data.order.add),
        export_order: checkPermission(permission_data.order.export_order),
    });


    const export_columns = [
        {
            title: "Order Ref",
            key: "ref_no",
        },
        {
            title: "Vendor",
            key: "vendor_name",
        },
        {
            title: "Customer Name",
            key: "customer_name",
        },
        {
            title: "Customer Contact Number",
            key: "customer_contact_number_view",
        },
        {
            title: "Customer Email",
            key: "customer_email",
        },
        {
            title: "Customer Email",
            key: "customer_email",
        },
        {
            title: "Recipient Name",
            key: "recipient_name",
        },
        {
            title: "Recipient Contact Number",
            key: "recipient_contact_number_view",
        },
        {
            title: "Items Price",
            key: "items_price",
        },
        {
            title: "Delivery Fees",
            key: "delivery_fees",
        },
        {
            title: "Net Total",
            key: "net_total",
        },
        {
            title: "Scheduled Date",
            key: "delivery_date_view",
        },
        {
            title: "Status",
            key: "status",
        },
        {
            title: "Delivery By",
            key: "delivery_by",
        },
        {
            title: "Order At",
            key: "createdAt",
            format: value => viewDateAndTime(value),
        },
    ]

    const [columns] = useState([
        {
            title: "Ref",
            dataIndex: "ref_no",
            width: "8%"
        },
        {
            title: "Vendor",
            dataIndex: "vendor_name",
            width: "12%",
            hide: !is_admin
        },
        {
            title: "Customer",
            dataIndex: "customer_name",
            width: "12%"
        },
        {
            title: "Contact Number",
            dataIndex: "customer_contact_number",
            width: "12%",
        },
        {
            title: "Net Total",
            dataIndex: "net_total",
            width: "10%",
            align: "center",
            render: (value) => value + " KWD"
        },
        {
            title: "Scheduled Date",
            dataIndex: "delivery_date_view",
            width: "12%",
            align: "center",
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "12%",
            align: "center",
        },
        {
            title: "Delivery By",
            dataIndex: "delivery_by",
            width: "10%",
        },
        {
            title: "Order At",
            dataIndex: "created_at_view",
            width: "15%",
            align: "center",
        },
    ]);
    //
    // const onDelete = async (row_data) =>{
    //     let response = await httpRequestWithAuth(`${OrdersURL}${row_data.id}`, "DELETE");
    //     if (response.status){
    //         openNotification("success", "Delete Order", `${row_data.ref_no} order has been deleted`)
    //     }else{
    //         openNotification("error", "Delete Order", response.error_feedback.non_field_errors)
    //     }
    // }

    const handleFilterChange = (name, value) => {
        let temp_filter = {...filter};
        temp_filter[name] = value;
        setFilter(temp_filter);
        setReload(Math.random());
    }

    const onEdit = async (row_data) => {
        props.history.push(`/${slug}/orders/${row_data.id}`)
    }
    //
    // const onAdd = async () =>{
    //     props.history.push(`/${slug}/orders/new`)
    // }

    // const freeAction = (
    //     <Hide hide={!order_perm.add}>
    //         <AddButton onClick={onAdd}>Add Order</AddButton>
    //     </Hide>
    // );

    const filter_toolbar = (
        <>
            <Row gutter={[10, 10]}>

                <Col xs={24} md={6}>
                    <CustomSelectField
                        allow_empty
                        label="Status"
                        name="status"
                        value={filter.status}
                        onChange={handleFilterChange}
                        options={order_status_options}
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomSelectField
                        allow_empty
                        label="Delivery By"
                        name="delivery_by"
                        value={filter.delivery_by}
                        onChange={handleFilterChange}
                        options={delivery_by_options}
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomDateField
                        allow_empty
                        label="Scheduled Date"
                        name="delivery_date"
                        value={filter.delivery_date}
                        onChange={handleFilterChange}
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomDateField
                        views={["year", "month"]}
                        open_on="year"
                        format="MMM-YYYY"
                        label="Order Month"
                        name="month"
                        value={filter.month}
                        onChange={handleFilterChange}
                        placeholder="Select month"
                    />
                </Col>
            </Row>
        </>
    )

    return (
        <Row>
            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Orders"
                    allow_export={order_perm.export_order}
                    export_columns={export_columns}
                    reload_data={reload}
                    columns={columns}
                    fetch_url={OrdersURL}
                    // free_action={order_perm.add && freeAction}
                    // onDelete={order_perm.delete && onDelete}
                    onEdit={onEdit}
                    edit_label="View"
                    remove_action_icon
                    filter_toolbar={filter_toolbar}
                    custom_params={filter}
                />
            </Col>
        </Row>
    )
}
