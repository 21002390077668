import React from "react";
import {Select} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {CustomTextField} from "./CustomTextField";

const {Option} = Select;


const CustomSelectField = (props) => {
    const onSelect = (new_value) => {
        if (!props.view_only && !props.disabled) {
            props.onChange(props.name, new_value)
        }
    }

    if (props.view_value_only && (props.view_only || props.disabled)){
        return <CustomTextField {...props}/>
    }

    return (
        <CustomFieldWrapper {...props}>
            <Select
                name={props.name}
                value={props.value}
                mode={props.mode}
                onChange={onSelect}
                className={`${props.className} w-100`}
                style={props.style}
                status={props.error ? "error" : null}
                disabled={props.view_only || props.disabled}
            >

                {props.allow_empty && (
                    <Option
                    value=""
                    >
                    ---------
                    </Option>
                )}

                {(props.options || []).map((option, index) => (
                    <Option
                        key={index}
                        value={option.value}
                        className={option.className}
                    >


                        {option.title}
                    </Option>
                ))}
            </Select>

        </CustomFieldWrapper>
    );
};

export default CustomSelectField;
