import DynamicAutocomplete from "./DynamicAutocomplete";
import {VendorsBrandsListAllURL} from "../../urls";

export const VendorBrandAutoComplete = (props)=>{
    const vendor = props.vendor || "";

    return(
        <DynamicAutocomplete
            {...props}
            fetch_url={VendorsBrandsListAllURL}
            title_key="name_en"
            mode={props.multiple ? "multiple":""}
            params_array={[vendor]}
            params={{vendor}}
        />
    )
}
