import {
    CompassFilled,
    DashboardFilled,
    EnvironmentOutlined,
    GlobalOutlined,
    MailOutlined,
    UnlockFilled,
    UserOutlined
} from "@ant-design/icons";
import React, {useState} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {checkOnePermission, checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {getUserType} from "../../global/auth_functions";

const SidebarMenu = (props) => {
    const {slug} = props;
    const [user_type] = useState(getUserType());
    const  is_vendor = user_type === "Vendor";
    const  is_admin = user_type === "Admin";

    const [perm] = useState({
        is_vendor,
        is_admin,

        view_rule: checkPermission(permission_data.rule.view),
        view_user: checkPermission(permission_data.user.view),

        view_vendor: checkPermission(permission_data.vendor.view) && is_admin,
        view_vendor_document: checkPermission(permission_data.vendor_document.view) && is_admin,
        view_vendor_brand: checkPermission(permission_data.vendor_brand.view) && is_admin,
        view_vendor_category: checkPermission(permission_data.vendor_category.view) && is_admin,
        view_vendor_menu: checkOnePermission([permission_data.vendor.view, permission_data.vendor_category.view, permission_data.vendor_document.view, permission_data.vendor_brand.view]) && is_admin,

        view_advertisement: checkPermission(permission_data.advertisement.view) && is_admin,
        view_blog: checkPermission(permission_data.blog.view) && is_admin,

        view_online_payment: checkPermission(permission_data.online_payment.view) && is_admin,


        view_product_menu: checkOnePermission([permission_data.product.view, permission_data.product_category.view]),

        view_product: checkPermission(permission_data.product.view),
        view_product_category: checkPermission(permission_data.product_category.view),

        view_order: checkPermission(permission_data.order.view),

        view_promo_code: checkPermission(permission_data.promo_code.view),
    });


    const menuItems = [
        {
            key: "dashboard",
            className: "text-white fw-bold font-size-15",
            icon: <DashboardFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/dashboard`}>Dashboard</Link>,
        },
        perm.view_rule && {
            key: "rules",
            className: "text-white fw-bold font-size-15",
            icon: <UnlockFilled className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/rules`}>Rules</Link>,
        },
        perm.view_user && {
            key: "users",
            className: "text-white fw-bold font-size-15",
            icon: <UserOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/users`}>Users</Link>,
        },
        perm.view_order && {
            key: "orders",
            className: "text-white fw-bold font-size-15",
            icon: <UserOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/orders`}>Orders</Link>,
        },
        // perm.view_product && {
        //     key: "Products List",
        //     className: "text-white fw-bold font-size-15",
        //     icon: <MailOutlined className="text-white font-size-18 fw-bold me-2"/>,
        //     label: <Link className="text-white" to={`/${slug}/products`}>Products</Link>,
        //
        // },

        (perm.is_admin && perm.view_product_menu) && {
            key: "products",
            className: "text-white fw-bold font-size-15",
            icon: <MailOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: "Products",
            children: [
                perm.view_product && {
                    key: "Products List",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/products`}>List</Link>,
                },
                perm.view_product_category && {
                    key: "products-categories",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/products-categories`}>Categories</Link>,
                },
            ]
        },
        (perm.is_vendor && perm.view_product_menu) && {
            key: "products",
            className: "text-white fw-bold font-size-15",
            icon: <MailOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: <Link className="text-white" to={`/${slug}/products`}>Products</Link>,
        },
        (perm.is_admin && perm.view_vendor_menu) && {
            key: "vendors",
            className: "text-white fw-bold font-size-15",
            icon: <MailOutlined className="text-white font-size-18 fw-bold me-2"/>,
            label: "Vendors",
            children: [
                perm.view_vendor && {
                    key: "Vendors List",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/vendors`}>Vendors</Link>,
                },
                perm.view_vendor_category && {
                    key: "vendors-categories",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/vendors-categories`}>Categories</Link>,
                },
                perm.view_vendor_document && {
                    key: "vendors-documents",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/vendors-documents`}>Documents</Link>,
                },
                perm.view_vendor_brand && {
                    key: "vendors-documents",
                    className: "text-white fw-bold font-size-15",
                    icon: (
                        <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
                    ),
                    label: <Link className="text-white" to={`/${slug}/vendors-brands`}>Brands</Link>,
                },
            ]
        },
        perm.view_advertisement && {
            key: "advertisement",
            className: "text-white fw-bold font-size-15",
            icon: (
                <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
            ),
            label: <Link className="text-white" to={`/${slug}/advertisements`}>Advertisement</Link>,
        },
        perm.view_blog && {
            key: "blogs",
            className: "text-white fw-bold font-size-15",
            icon: (
                <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
            ),
            label: <Link className="text-white" to={`/${slug}/blogs`}>Blogs</Link>,
        },
        perm.view_promo_code && {
            key: "blogs",
            className: "text-white fw-bold font-size-15",
            icon: (
                <MailOutlined className="text-white font-size-18 fw-bold me-2"/>
            ),
            label: <Link className="text-white" to={`/${slug}/promo-codes`}>Promo Codes</Link>,
        },
    ];


    return (
        <Menu
            className="custom text-white fw-bold font-size-17 pb-4"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            items={menuItems}
        />
    );
};
export default SidebarMenu;
