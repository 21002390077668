import {useState} from "react";
import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {AdvertisementsURL} from "../../../../global/urls";
import {customMoment, isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import CustomCheckBox from "../../../../global/components/fields/CustomCheckBox";
import CustomSelectField from "../../../../global/components/fields/CustomSelectField";
import {CustomDateTimeField} from "../../../../global/components/fields/CustomDateTimeField";
import {ImageUploaderWithView} from "../../../../global/components/ImageUploaderWithView";
import {WeekDaysSelectChips} from "../../../../global/components/WeekDaysSelectChips";
import {VendorAutoComplete} from "../../../../global/components/fields/VendorAutoComplete";
import moment from "moment";
import Hide from "../../../../global/components/Hide";

const page_name_options =[
    {title: "Mobile Home", value:"Mobile_Home"},
]

export const AdvertisementsFormModal = props => {
    const [data, setData] = useState({
        is_active: true,
        page_name: "Mobile Home",
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "image") {
            temp_data[`display_${name}`] = isValidValue(value)?URL.createObjectURL(value):null;
        }

        setData(temp_data);
    }



    const onSave = async () => {
        await setLoading(true);

        const form_data = new FormData();
        Object.keys(data).map(Name=>{
            if (['active_from', 'active_to'].includes(Name)){
                if (isValidValue(data[Name])){
                    form_data.append(Name, customMoment(data[Name]).format("DD-MM-YYYY hh:mm"))
                }else{
                    form_data.append(Name, "")
                }
            }else{
                form_data.append(Name, data[Name] || "")
            }
        })


        const is_new = !isValidValue(data.id);
        let response;


        if (is_new) {
            response = await httpRequestWithAuth(AdvertisementsURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${AdvertisementsURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Advertisement", `${data.title_en} advertisement has been added`)
            } else {
                openNotification("success", "Update Advertisement", `${data.title_en} advertisement has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Advertisement Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <ImageUploaderWithView
                        label="Upload Image"
                        display_image={data.display_image}
                        name="image"
                        value={data.image}
                        onChange={handleFieldChange}
                        error={helper_text.image !== undefined}
                        helper_text={helper_text.image}
                    />
                </Col>



                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Title En"
                        name="title_en"
                        value={data.title_en}
                        onChange={handleFieldChange}
                        error={helper_text.title_en !== undefined}
                        helper_text={helper_text.title_en}
                    />
                </Col>


                <Col xs={24} md={8}>
                    <CustomTextField
                        required
                        label="Title Ar"
                        name="title_ar"
                        value={data.title_ar}
                        onChange={handleFieldChange}
                        error={helper_text.title_ar !== undefined}
                        helper_text={helper_text.title_ar}
                    />
                </Col>



                <Col xs={24} md={8}>
                        <CustomSelectField
                            label="Page"
                            name="page_name"
                            value={data.page_name}
                            onChange={handleFieldChange}
                            error={helper_text.page_name !== undefined}
                            helper_text={helper_text.page_name}
                            options={page_name_options}
                        />
                    </Col>


                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
