import React, {useEffect} from "react";
import { isValidValue } from "../helper_functions";
import Hide from "./Hide";
import {Button} from "react-bootstrap";
import {Avatar, Image} from "antd";
import {UserOutlined} from "@ant-design/icons";
import UserIcon from "../../assets/icon/user_icon.png";

const CustomFileUpload = (props) => {
    const avatar_shape = props.avatar_shape || false ;

    const maxWidth = props.maxWidth || 100
    const handleChange = (e) => {
      let file;

      if (isValidValue(e)){
          file = e.target.files[0];
      }
      props.onChange(props.name, isValidValue(file) ? file : "");
  };


  return (

<>
    <div className="w-100 text-center">
    <div className="w-100 text-center d-flex justify-content-center">

    <Hide hide={isValidValue(props.value)}>
    {/*<Upload  onChange={handleChange} onDrop={handleChange} id={props.name} showUploadList={false}>*/}
    {/*    <Button type="primary" icon={<UploadOutlined />}>*/}
    {/*        {props.label}*/}
    {/*    </Button>*/}
    {/*</Upload>*/}
        <>
            <input
                className="d-none"
                type="file"
                id={props.name}
                onChange={handleChange}
                accept={props.accept}
            />




            <Button
                as="label"
                id={props.name}
                htmlFor={props.name}
                className={`h-100 w-100 custom-button-bootstrap ${avatar_shape?"p-0": "p-2"}`}
                style={{maxWidth}}
            >

                <Hide hide={!avatar_shape}>
                    <img shape="square"  src={UserIcon} className="w-100 h-100 bg-white"/>
                </Hide>

                <Hide hide={avatar_shape}>
                        <span className="font-weight-bold w-100 h-100">{props.label}</span>
                </Hide>

            </Button>
        </>
    </Hide>

    <Hide hide={!isValidValue(props.value) || props.hide_remove}>
        <div className="d-block">
        <p className="m-0">the file has been uploaded</p>
        <p className="m-0">{(props.value || {}).name}</p>
        <Button className="mt-2" variant="danger" onClick={()=>handleChange(null)}>
            Remove
        </Button>
        </div>
    </Hide>

    </div>
        <span className={props.error && "text-danger"}>{props.helper_text}</span>

    </div>

</>
  );
};

export default CustomFileUpload;

