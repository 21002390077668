import React, {useEffect, useState} from "react";
import {getUserSLug, isAuthenticated, logout} from "../global/auth_functions";
import {createAxiosInstance} from "../global/axios_instance";
import {getAllPermissions} from "../global/permissions";
import {Loading} from "../global/components/Loading";
import {useParams} from "react-router-dom";
import Layout from "../layout/Layout";

export const ProtectedApp = props => {
    const params = useParams();
    const slug = params.slug || "";
    const [user_slug] = useState(getUserSLug());

    const [is_loading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const is_auth = await isAuthenticated();
            if (is_auth && user_slug === slug) {
                const token = await localStorage.getItem("access_token")
                await createAxiosInstance(token, slug);
                await getAllPermissions();
            } else {
                logout();
                props.history.push(`/${slug}/signin`);
            }

            await setIsLoading(false);
        }

        fetchData();
    }, [])

    if (is_loading) {
        return <Loading full_page/>
    }

    return (
    <Layout {...props} slug={slug}/>
)
}
