import React from "react";
import {Input, Form} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import 'react-quill/dist/quill.snow.css';
import Hide from "../Hide";
import ReactQuill, { Quill } from "react-quill";

export const CustomTextAreaField = (props) => {
    const handleReactQuillFieldChange = (new_value, delta, source) => {
        if (!props.view_only && !props.disabled && source === "user") {
            props.onChange(props.name, new_value);
        }
    };

    const handleFieldChange = (new_value) => {
        if (!props.view_only && !props.disabled && new_value !== props.value) {
            props.onChange(props.name, new_value);
        }
    };


    return (
        <CustomFieldWrapper {...props}>
                <Hide hide={!props.withTextEditor}>
                <Hide hide={props.view_only}>
                <ReactQuill
                    readOnly={props.view_only}
                    className={`w-100 ${props.error ?"border border-danger ": ""}`}
                    value={props.value || ""}
                    onChange={handleReactQuillFieldChange}
                />
                </Hide>

                    <Hide hide={!props.view_only}>
                        <div dangerouslySetInnerHTML={{__html: props.value || "<>"}} className="border p-2 w-100"/>
                    </Hide>

                    </Hide>

            <Hide hide={props.withTextEditor}>
            <Input.TextArea
                {...props}
                name={props.name}
                value={props.value}
                status={props.error ? "error" : null}
                onChange={e=>handleFieldChange(e.target.value)}
                rows={props.rows || 4}
            />
            </Hide>
        </CustomFieldWrapper>
    );
};
