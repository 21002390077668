import React, {useEffect, useState} from "react";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {isValidValue, showError} from "../../global/helper_functions";
import {Tabs} from "antd";
import Hide from "../../global/components/Hide";
import {CustomTabPane} from "../../global/components/CustomTabPane";
import {getUserType} from "../../global/auth_functions";
import {BlogDetails} from "./BlogDetails";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {BlogsURL} from "../../global/urls";
import {BlogGallery} from "./BlogGallery";


export const BlogInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};
    const [blog_id] = React.useState(url_params.id || 'new');
    const url_query = new URLSearchParams(props.location.search);
    const tab = (url_query.get("tab") || "1") + "";
    const [user_type] = useState(getUserType());
    const [current_tab, setCurrentTab] = useState("1");
    const [is_new] = useState(blog_id === "new");
    const [blog_data, setBlogData] = useState({});

    useEffect(() => {
        if ((!isValidValue(url_params.id) && props.location.pathname !== `/${slug}/blogs/new`) || (blog_id !== "new" && !checkPermission(permission_data.blog.view)) || (blog_id === "new" && !checkPermission(permission_data.blog.add))) {
            props.history.push(`/${slug}/blogs`);
        }
        setCurrentTab(tab);
    }, []);




    const handleTabsChange = (new_value, extra_params={}) => {
        new_value = new_value + "";
        let params_attributes = {tab: new_value, ...extra_params};
        const params = new URLSearchParams(params_attributes);

        props.history.push({
            pathname: props.location.pathname,
            search: params.toString()
        });

        setCurrentTab(new_value);
    };



    const onCancel = () => {
        props.history.push(`/${slug}/blogs`);
    }

    const getBlogData = async () => {
        let response = await httpRequestWithAuth(`${BlogsURL}${blog_id}`, "GET");
        if (response.status) {
            setBlogData(response.data);
        } else {
            showError("Get Blog Data", response.error_feedback.non_field_errors);
            onCancel();
        }

        return response
    }



    return (
        <>


            <div className="m-0 p-0 d-md-flex justify-content-between">
                <h5 className="mb-3 mt-2">{blog_id === "new" ? "Add Blog" :  (<>Edit Blog > <span className="text-black-50">{blog_data.ref_no}</span></>)}</h5>
            </div>


            <div className="paper pt-0 w-100">


                <Tabs activeKey={current_tab} onChange={handleTabsChange}>
                    <CustomTabPane tab="Details" key="1"/>
                    <CustomTabPane tab="Gallery" key="2"
                                   disabled={is_new || !checkPermission(permission_data.blog.view)}/>                </Tabs>


            </div>

                    <Hide hide={current_tab !== '1'}>
                        <BlogDetails
                            {...props}
                            getBlogData={getBlogData}
                            current_tab={current_tab}
                            is_new={is_new}
                            user_type={user_type}
                            blog_id={blog_id}
                            setBlogData={setBlogData}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>


                    <Hide hide={current_tab !== '2'}>
                        <BlogGallery
                            {...props}
                            getBlogData={getBlogData}
                            current_tab={current_tab}
                            is_new={is_new}
                            user_type={user_type}
                            blog_id={blog_id}
                            setBlogData={setBlogData}
                            handleTabsChange={handleTabsChange}
                        />
                    </Hide>

        </>)
}
