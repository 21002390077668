import React, {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {UpdateOrderDeliveryBy} from "../../../global/urls";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {delivery_by_options} from "../../../global/variables";




export const UpdateOrderDeliveryByModal = props => {
    const [data, setData] = useState({
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {

        let temp_data = {...data};
        temp_data[name] = value || "";

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);

        let response = await httpRequestWithAuth(`${UpdateOrderDeliveryBy(data.id)}`, "PATCH", data);

        if (response.status) {
                openNotification("success", "Update Order Delivery By", `${data.ref_no} order has been updated`)
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="xs" title="Update Order Delivery By" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <CustomSelectField
                        label="Delivery By"
                        name="delivery_by"
                        value={data.delivery_by}
                        onChange={handleFieldChange}
                        error={helper_text.delivery_by !== undefined}
                        helper_text={helper_text.delivery_by}
                        options={delivery_by_options}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
