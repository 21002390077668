import CustomModal from "../../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {useState} from "react";
import {httpRequestWithAuth} from "../../../../global/axios_instance";
import {VendorsDocumentURL} from "../../../../global/urls";
import {isValidValue, openNotification} from "../../../../global/helper_functions";
import {ModelActionButton} from "../../../../global/components/ModelActionButton";
import {CustomTextField} from "../../../../global/components/fields/CustomTextField";
import CustomDragNDrop from "../../../../global/components/CustomDragNDrop";
import {VendorAutoComplete} from "../../../../global/components/fields/VendorAutoComplete";


export const VendorDocumentFormModal = props => {
    const [data, setData] = useState({
        ...props.data
    });
    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "file"){
            temp_data[`${name}_filename`] = isValidValue(value)? value.name: "";
        }

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);

        const is_new = !isValidValue(data.id);
        let response;


        let form_data = new FormData();

        Object.keys(data).map(Name=> {
            if (isValidValue(data[Name])) {
                form_data.append(Name, data[Name]);
            }
        });



        if (is_new) {
            response = await httpRequestWithAuth(VendorsDocumentURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${VendorsDocumentURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add Vendor Document", `${data.name} document has been added`)
            } else {
                openNotification("success", "Update Vendor Document", `${data.name} document has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="Vendor Document Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>


                <Col xs={24}>
                    <CustomDragNDrop
                        display_value={data.file_filename}
                        name="file"
                        value={data.file}
                        onChange={handleFieldChange}
                        error={helper_text.file !== undefined}
                        helper_text={helper_text.file}
                        accept="image/* application/pdf"
                    />
                </Col>



                <Col xs={24} md={12}>
                    <VendorAutoComplete
                        label="Vendor"
                        name="VendorId"
                        value={data.VendorId}
                        onChange={handleFieldChange}
                        error={helper_text.VendorId !== undefined}
                        helper_text={helper_text.VendorId}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Name"
                        name="name"
                        value={data.name}
                        onChange={handleFieldChange}
                        error={helper_text.name !== undefined}
                        helper_text={helper_text.name}
                    />
                </Col>



                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
