import React, {useState} from "react";
import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {UpdateOrderStatus} from "../../../global/urls";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";




const status_options =[
    {title: "New Order", value:"New Order"},
    {title: "Under Process", value:"Under Process"},
    {title: "Ready For Delivery", value:"Ready For Delivery"},
    {title: "Done", value:"Done"},
    {title: "Cancelled", value:"Cancelled"},
]

export const UpdateOrderStatusModal = props => {
    const [data, setData] = useState({
        ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});

    const handleFieldChange = (name, value) => {

        let temp_data = {...data};
        temp_data[name] = value || "";

        setData(temp_data);
    }

    const onSave = async () => {
        await setLoading(true);

        let response = await httpRequestWithAuth(`${UpdateOrderStatus(data.id)}`, "PATCH", data);

        if (response.status) {
                openNotification("success", "Update Order Status", `${data.ref_no} order has been updated`)
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal size="xs" title="Update Order Status" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <CustomSelectField
                        label="Status"
                        name="status"
                        value={data.status}
                        onChange={handleFieldChange}
                        error={helper_text.status !== undefined}
                        helper_text={helper_text.status}
                        options={status_options}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}
