import CustomModal from "../../../global/components/CustomModal";
import {Col, Row} from "antd";
import {useState} from "react";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import CustomSelectField from "../../../global/components/fields/CustomSelectField";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {UsersURL} from "../../../global/urls";
import {isValidValue, openNotification} from "../../../global/helper_functions";
import {ModelActionButton} from "../../../global/components/ModelActionButton";
import {CustomPhoneNumberField} from "../../../global/components/fields/CustomPhoneNumberField";
import Hide from "../../../global/components/Hide";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {VendorAutoComplete} from "../../../global/components/fields/VendorAutoComplete";
import {MultiRulesAutoComplete} from "../../../global/components/fields/MultiRulesAutoComplete";
import {getUserType} from "../../../global/auth_functions";


const user_type_options = [
    {title: "Admin", value: "Admin"},
    {title: "Vendor", value: "Vendor"},
    {title: "Customer", value: "Customer"},
];

export const UserFormModal = props => {
    const [user_type] = useState(getUserType());

    const [data, setData] = useState({
        rules_id: [],
        is_active: true,
        change_password: !isValidValue(props.data.id),
        user_type, ...props.data
    });

    const [loading, setLoading] = useState(false);
    const [helper_text, setHelperText] = useState({});
    const [auth_user_type] = useState(getUserType());

    const handleFieldChange = (name, value) => {

        let temp_data = {...data};
        temp_data[name] = value;
        setData(temp_data);
        let temp_helper = {...helper_text};
        delete temp_helper[name];

        if (['confirm_password', 'password'].includes(name)) {
            if (temp_data.confirm_password !== temp_data.password && isValidValue(temp_data.confirm_password)) {
                temp_helper['confirm_password'] = "Password does not match";
            }
        }

        setHelperText(temp_helper);
    }

    const onSave = async () => {
        await setLoading(true);




        if (data.change_password && data.confirm_password !== data.password) {
            setHelperText({...helper_text, confirm_password: "Password does not match"})
            await setLoading(false);
            return
        }

        const is_new = !isValidValue(data.id);
        let response;

        if (is_new) {
            response = await httpRequestWithAuth(UsersURL, "POST", data);
        } else {
            response = await httpRequestWithAuth(`${UsersURL}${data.id}`, "PUT", data);
        }

        if (response.status) {
            if (is_new) {
                openNotification("success", "Add User", `${data.full_name} user has been added`)
            } else {
                openNotification("success", "Update User", `${data.full_name} user has been updated`)
            }
            props.handleModelClose(true);
        } else {
            setHelperText(response.error_feedback);
        }

        await setLoading(false);

    }

    return (
        <CustomModal title="User Form" visible={props.open} onCancel={props.handleModelClose}>
            <Row gutter={[12, 10]}>

                <Col xs={24} md={12}>
                    <CustomTextField
                        label="First Name"
                        name="first_name"
                        value={data.first_name}
                        onChange={handleFieldChange}
                        error={helper_text.first_name !== undefined}
                        helper_text={helper_text.first_name}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <CustomTextField
                        label="Last Name"
                        name="last_name"
                        value={data.last_name}
                        onChange={handleFieldChange}
                        error={helper_text.last_name !== undefined}
                        helper_text={helper_text.last_name}
                    />
                </Col>


                <Hide hide={user_type !== "Admin"}>
                <Col xs={24} md={8}>
                    <CustomSelectField
                        label="User Type"
                        name="user_type"
                        value={data.user_type}
                        onChange={handleFieldChange}
                        error={helper_text.user_type !== undefined}
                        helper_text={helper_text.user_type}
                        options={user_type_options}
                        disabled={isValidValue(data.id)}
                    />
                </Col>
                </Hide>


                <Col xs={24} md={user_type !== "Admin"?12:8}>
                    <CustomTextField
                        label="E-mail"
                        name="email"
                        value={data.email}
                        onChange={handleFieldChange}
                        error={helper_text.email !== undefined}
                        helper_text={helper_text.email}
                        type="email"
                    />
                </Col>


                <Col xs={24} md={user_type !== "Admin"?12:8}>
                    <CustomPhoneNumberField
                        label="Contact No."
                        name="contact_number"
                        value={data.contact_number}
                        onChange={handleFieldChange}
                        error={helper_text.contact_number !== undefined}
                        helper_text={helper_text.contact_number}
                    />
                </Col>


                <Hide hide={data.user_type !== "Vendor" || auth_user_type !== "Admin"}>
                    <Col xs={24}>
                        <VendorAutoComplete
                            label="Vendor"
                            name="VendorId"
                            value={data.VendorId}
                            onChange={handleFieldChange}
                            error={helper_text.VendorId !== undefined}
                            helper_text={helper_text.VendorId}
                            disabled={isValidValue(data.id)}
                        />
                    </Col>
                </Hide>

                <Hide hide={data.user_type === "Customer"}>
                    <Col xs={24}>
                        <MultiRulesAutoComplete
                            rule_for={data.user_type}
                            label="Rules"
                            name="rules_id"
                            value={data.rules_id}
                            onChange={handleFieldChange}
                            error={helper_text.rules_id !== undefined}
                            helper_text={helper_text.rules_id}
                        />
                    </Col>
                </Hide>

                <Hide hide={!data.change_password}>
                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Password"
                            name="password"
                            value={data.password}
                            onChange={handleFieldChange}
                            error={helper_text.password !== undefined}
                            helper_text={helper_text.password}
                            type="password"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            label="Confirm Password"
                            name="confirm_password"
                            value={data.confirm_password}
                            onChange={handleFieldChange}
                            error={helper_text.confirm_password !== undefined}
                            helper_text={helper_text.confirm_password}
                            type="password"
                        />
                    </Col>
                </Hide>




                <Hide hide={!isValidValue(data.id)}>
                    <Col xs={24}>
                        <CustomCheckBox
                            label="Change Password"
                            name="change_password"
                            value={data.change_password}
                            onChange={handleFieldChange}
                        />
                    </Col>
                </Hide>


                <Col xs={24}>
                    <CustomCheckBox
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                    />
                </Col>

                <Col xs={24} className="p-0">
                    <ModelActionButton
                        error_msg={helper_text.non_field_errors}
                        loading={loading}
                        onSave={onSave}
                        onCancel={props.handleModelClose}
                    />
                </Col>

            </Row>
        </CustomModal>
    )
}