import React, {useEffect, useState} from "react";
import {DatePicker} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import moment from "moment";
import {customMoment, isValidValue} from "../../helper_functions";

export const CustomDateField = (props) => {
    const views = props.views || ["year", "month", "date"];
    const open_on = props.open_on || "date";
    const [picker, setPicker] = useState(open_on);
    const [open, setOpen] = useState(false);

    const view_format = props.format || "DD-MM-YYYY";
    const value_format = props.format || "YYYY-MM-DD";

    const [date_value, setDateValue] = useState(undefined);

    const handleFieldChange =  (date) => {
        if (!props.view_only && !props.disabled) {
            let new_value = moment(date)

            if (new_value.isValid()){
                new_value = new_value.format(value_format);
            }else{
                new_value = "";
            }

            if (new_value !== props.value){
                props.onChange(props.name, new_value);
            }

            if(isValidValue(open_on)){
                if(picker === "year" && views.includes("month")){
                    setPicker("month")
                }else if(picker === "month" && views.includes("date")){
                    setPicker("date")
                }else{
                    setOpen(false)
                }

            }else{
                setOpen(false)
            }

        }
    };


    useEffect(() => {
        if (isValidValue(props.value)) {
            const new_date = customMoment(props.value, value_format);
            if (new_date.isValid()) {
                setDateValue(new_date);
            } else {
                setDateValue(undefined)
                console.error(props.name, "not valid date")
            }
        } else {
            setDateValue(undefined)
        }

    }, [props.value])


    const onOpenChange = (is_open) =>{
        if (!props.view_only && !props.disabled) {
            if (is_open) {
                setOpen(true);
                setPicker(props.open_on)
            } else {
                if (!isValidValue(open_on)) {
                    setOpen(false);
                }
            }
        }else{
            setOpen(false);
        }
    }

    const onBlur = () =>{
        setOpen(false)
    }

    const onPanelChange =  (value) =>{

        // handleFieldChange(value);
    }

    const onChange = (date) => {
      if (!isValidValue(date)){
          handleFieldChange("");
      }
    }

    return (
        <CustomFieldWrapper {...props}>
            <DatePicker
                placeholder={props.placeholder}
                open={open}
                onBlur={onBlur}
                onPanelChange={onPanelChange}
                onOpenChange={onOpenChange}
                onChange={onChange}
                onSelect={handleFieldChange}
                name={props.name}
                value={date_value}
                format={view_format}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabledDate={props.disabledDate}
                status={props.error ? "error" : null}
                picker={picker}
                className={`w-100 mt-0 ${!props.disabled && props.view_only?"bg-white text-black-50":""} ${props.className}`}
                disabled={props.disabled || props.view_only}

            />
        </CustomFieldWrapper>
    );
};
