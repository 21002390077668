import Logo from "../../assets/img/logo.png";

export const Welcome = props =>{
    return(
        <div className="vh-100 vw-100 d-flex flex-column justify-content-center align-items-center">
            <img src={Logo} className="w-50" style={{maxWidth: 450}}/>
            <h1 className="mt-5">Welcome to Gallerias</h1>
        </div>
    )
}
