import {Button, Col, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {VendorsDocumentURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import Hide from "../../../global/components/Hide";
import {VendorDocumentFormModal} from "./components/VendorDocumentFormModal";

export const VendorDocuments = props => {
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [vendor_document_perm] = useState({
        delete: checkPermission(permission_data.vendor_document.delete),
        edit: checkPermission(permission_data.vendor_document.edit),
        add: checkPermission(permission_data.vendor_document.add),
    });

    const [columns] = useState([
        {
            title: "Vendor",
            dataIndex: "vendor_name",
            width: "15%",
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "15%",
        },
        {
            title: "",
            dataIndex: "display_file",
            width: "20%",
            align: "center",
            render: (value) => {
                    return (
                        <a href={value} target="_blank">
                            <Button>
                                View Document
                            </Button>
                        </a>
                    )
            }
        },
    ])

    const handelOpenFormData = (data = {}) => {
        setFormData(data)
        setOpenFormData(true)
    }


    const handelCloseFormData = (reload_data = false) => {
        setFormData({});
        setOpenFormData(false);

        if (reload_data) {
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) => {
        let response = await httpRequestWithAuth(`${VendorsDocumentURL}${row_data.id}`, "DELETE");

        if (response.status) {
            openNotification("success", "Delete Vendor Document", `${row_data.name} document has been deleted`)
        } else {
            openNotification("error", "Delete Vendor Document", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) => {
        handelOpenFormData(row_data)
    }

    const onAdd = async () => {
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Vendor Document</AddButton>
        </>
    );

    return (
        <Row>
            <Hide hide={!open_from_data}>
                <VendorDocumentFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Vendors Documents"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={VendorsDocumentURL}
                    free_action={vendor_document_perm.add && freeAction}
                    onDelete={vendor_document_perm.delete && onDelete}
                    onEdit={vendor_document_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
