import React, {useEffect, useState} from "react";
import { Container, Content, Footer, Col, Header } from "rsuite";
import CustomSideBar from "./components/CustomSideBar";
import {Button} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {logout} from "../global/auth_functions";
import {Routes} from "../routes/Router";
import Hide from "../global/components/Hide";

const Layout = (props) => {
  const [side_menu_toggle, setSideMenuToggle] = useState(false);
  const [scrollTop, setScrollTop] = useState();

    const onScroll = () => {
      const scroll_top_value = document.getElementById("content").scrollTop
      setScrollTop(scroll_top_value);
    }

  const handleToggleDrawer = () => {
    setSideMenuToggle(!side_menu_toggle);
  };

  const onLogout = () =>{
    logout();
    props.history.push(`/${props.slug}/signin`)
  }

  return (
    <Container className="main_container">
      <CustomSideBar
        side_menu_toggle={side_menu_toggle}
        setSideMenuToggle={handleToggleDrawer}
        {...props}
      />

      <Container className="page" id="content" onScroll={onScroll}>
        <Header className="toolbar_header">
          <Hide hide_up_xl>
            <Button
              onClick={handleToggleDrawer}
              icon={<MenuOutlined />}
            ></Button>
          </Hide>

          <div className="ms-auto" />
          <Button  onClick={onLogout}>
            Logout
          </Button>
        </Header>

        <Content className="p-3 mt-3-100 h-auto mb-5">
          <Routes {...props} scroll_height={scrollTop}/>
        </Content>

      </Container>
    </Container>
  );
};

export default Layout;
