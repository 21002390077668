import {Button, Col, Image, Row} from "antd";
import {CustomDynamicTable} from "../../../global/components/CustomDynamicTable";
import {useState} from "react";
import {VendorsURL} from "../../../global/urls";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {openNotification, showError, showInfo} from "../../../global/helper_functions";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {AddButton} from "../../../global/components/AddButton";
import {VendorFormModal} from "./components/VendorFormModal";
import Hide from "../../../global/components/Hide";
import {IsActiveView} from "../../../global/components/IsActiveView";

export const Vendors = props =>{
    const [open_from_data, setOpenFormData] = useState(false);
    const [form_data, setFormData] = useState({});
    const [reload, setReload] = useState(false);

    const [vendor_perm] = useState({
        delete: checkPermission(permission_data.vendor.delete),
        edit: checkPermission(permission_data.vendor.edit),
        add: checkPermission(permission_data.vendor.add),
    });

    const [columns] = useState([
        {
            title: "Logo",
            dataIndex: "display_logo",
            width: "20%",
            align: "center",
            render:(value)=>(
                <Image src={value} width="100%"/>
            )
        },
        {
            title: "Name",
            dataIndex: "name",
            width: "20%"
        },
        {
            title: "Slug",
            dataIndex: "slug",
            width: "20%",
        },
        // {
        //     title: "Contact E-mail",
        //     dataIndex: "vendor_contact_email",
        //     width: "15%"
        // },
        // {
        //     title: "Contact No.",
        //     dataIndex: "vendor_contact_number",
        //     width: "15%"
        // },
        {
            title: "Category",
            dataIndex: "vendor_categories_name_en",
            width: "15%",
        },
        {
            title: "Activate",
            dataIndex: "is_active",
            width: "5%",
            align:"center",
            render: (value)=><IsActiveView value={value} view_only={vendor_perm.edit}/>
        },

    ])

    const handelOpenFormData = (data={})=>{
        setFormData(data)
        setOpenFormData(true)
    }

    const handelCloseFormData = (reload_data=false)=>{
        setFormData({});
        setOpenFormData(false);

        if (reload_data){
            setReload(Math.random());
        }

    }

    const onDelete = async (row_data) =>{
        let response = await httpRequestWithAuth(`${VendorsURL}${row_data.id}`, "DELETE");
        if (response.status){
            openNotification("success", "Delete Vendor", `${row_data.name} vendor has been deleted`)
        }else{
            openNotification("error", "Delete Vendor", response.error_feedback.non_field_errors)
        }
    }

    const onEdit = async (row_data) =>{
        handelOpenFormData(row_data)
    }

    const onAdd = async () =>{
        handelOpenFormData()
    }

    const freeAction = (
        <>
            <AddButton onClick={onAdd}>Add Vendor</AddButton>
        </>
    );

    return(
        <Row>
            <Hide hide={!open_from_data}>
                <VendorFormModal
                    open={open_from_data}
                    data={form_data}
                    handleModelClose={handelCloseFormData}
                />
            </Hide>

            <Col xs={24} className="paper">
                <CustomDynamicTable
                    title="Vendors"
                    reload_data={reload}
                    columns={columns}
                    fetch_url={VendorsURL}
                    free_action={vendor_perm.add && freeAction}
                    onDelete={vendor_perm.delete && onDelete}
                    onEdit={vendor_perm.edit && onEdit}
                />
            </Col>
        </Row>
    )
}
