import React from "react";
import { Input, Form } from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {toSlugFormat} from "../../helper_functions";


const slug_tooltip = "Slug is the unique identifying part of a web address, typically at the end of the URL, \n\n please avoid special character & spaces, you can use dash ( - )"
export const CustomSlugField = (props) => {
  const onKeyPress = (e) => {
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const old_value = e.target.value;
    let new_value = old_value.slice(0, start) + old_value.slice(end);
    e.target.value = toSlugFormat(new_value);
    props.onChange(props.name, e.target.value);
  };


  const handleFieldChange = (e) => {
    if (!props.view_only && !props.disabled) {
      props.onChange(e.target.name, e.target.value);
    }
  };

  return (
    <CustomFieldWrapper {...props} tooltip={props.tooltip? `${props.tooltip},  ${slug_tooltip}`: slug_tooltip}>
      <Input
        onBlur={onKeyPress}
        {...props}
        onChange={handleFieldChange}
        name={props.name}
        value={props.value}
        bordered
        placeholder={props.placeholder}
        status={props.error ? "error" : null}
        type={props.type || "string"}
        className="mt-0"
      />
    </CustomFieldWrapper>
  );
};
