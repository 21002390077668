import {Col, Row} from "antd";

export const ViewInfo = props =>{
    return(
        <Row className={`align-items-center ${props.container_class_name} `}>
            <Col xs={24}>
            <h2 className="font-size-12 text-black-50">
                {props.title}
            </h2>

            <h2 className={`font-size-14 ms-2 mb-0 pre-line ${props.className}`}>
                {props.value || "-------"}
            </h2>
            </Col>
        </Row>
    )
}
