import React, {useState} from "react";
import {InputGroup, Form, Button} from "react-bootstrap";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {isValidValue} from "../../helper_functions";
import {UploadFileToServerURL} from "../../urls";
import {httpRequestWithAuth} from "../../axios_instance";

export const CustomUploadField = props =>{
    const upload_to_server = props.upload_to_server;
    const [uploading, setUploading] = useState(false);
    const [upload_error, setUploadError] = useState("");

    const onChange = async (e) =>{
        setUploadError("");
        let new_value = "";
;        if (!props.view_only && !props.disabled) {
            if (isValidValue(e)){
                new_value = e.target.files[0]
            }

            if (!upload_to_server || !isValidValue(new_value)){
                props.onChange(props.name, new_value);
            }else{
                setUploading(new_value.name);
                const form_data = new FormData();
                form_data.append('file', new_value);
                let response = await httpRequestWithAuth(UploadFileToServerURL, "POST", form_data);
                if (response.status){
                    props.onChange(props.name, response.data);
                }else{
                    setUploadError("uploading file failed")
                }
                setUploading(false);
            }
        }
    }


    return(
        <CustomFieldWrapper {...props} helper_text={props.helper_text || upload_error}>
            {/*<Input*/}
            {/*    {...props}*/}
            {/*    onChange={handleFieldChange}*/}
            {/*    name={props.name}*/}
            {/*    value={props.value}*/}
            {/*    bordered*/}
            {/*    placeholder={props.placeholder}*/}
            {/*    status={props.error ? "error" : null}*/}
            {/*    type={props.type || "string"}*/}
            {/*    className="mt-0"*/}
            {/*/>*/}
                {uploading?(
                    <InputGroup>
                        <Button variant="outline-danger" className="m-0 h-100" onClick={()=>onChange("")}>
                            upLoading..
                        </Button>
                        <Form.Control
                            value={uploading}
                        />
                    </InputGroup>
                ):props.value ? (
                    <InputGroup>
                        <Button variant="outline-danger" className="m-0 h-100" onClick={()=>onChange("")}>
                            Remove File
                        </Button>
                        <Form.Control
                            readOnly
                            id={props.display_value}
                            value={props.display_value || ""}
                        />
                    </InputGroup>
                ) : (
                    <>
                        <Form.Control type="file" required
                                      isInvalid={props.error}
                                      placeholder={props.placeholder}
                                      onChange={onChange}
                                      value={props.value || ""}
                                      className="ant-input"
                        />
                    </>
                )}

        </CustomFieldWrapper>

    )
}