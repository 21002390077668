import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, notification, message } from "antd";
import moment from "moment";
const { confirm, info, warning, error } = Modal;


export const customMoment = (date=moment(), format=null) =>{
  return moment.utc(date, format).utcOffset('+0000', true)
}


export const isValidValue = (value) => {
  return ![undefined, null, ""].includes(value);
};

export const viewDateAndTime = (value) =>{
  const new_value = moment(value)
  if (!new_value.isValid()){
    return "";
  }

  return new_value.format("DD-MM-YYYY hh:mm A")
}

export const getRandomInt = (min=65, max=90) =>{
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const generateRandomString = (length, from_code=65, to_code=90) =>{
  let value = "";
  for (let i = 0; i < length; i++) {
    const chr_code = getRandomInt(from_code, to_code)
    const chr_value = String.fromCharCode(chr_code);
    value += chr_value
  }
  return value
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const copyText = (text)=>{
  navigator.clipboard.writeText(text);
}

export const showError = (title, content) => {
  error({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    centered: true,
  });
};

export const showWarning = (title, content) => {
  warning({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    centered: true,
  });
};

export const showConfirm = (
  title,
  content,
  onOk = () => {},
  onCancel = () => {}
) => {
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    },
    centered: true,
  });
};

export const showInfo = (title, content, onOk=()=>{}) => {
  info({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    centered: true,
    onOk: onOk
  });
};

export const openNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 5,
    // placement: "topLeft"
  });
};

export const openMessage = (type, content) => {
  message[type](content);
};

export const downloadDataAsCsv = (name, data = [], columns = null) => {
  let csvContent = "data:text/csv;charset=utf-8,";

  let header = "";
  if (isValidValue(columns)) {
    Object.keys(columns).map((Key) => {
      return (header += columns[Key] + ",");
    });
  } else {
    if (data.length > 0) {
      Object.keys(data[0]).map((Key) => {
        return (header += Key + ",");
      });
    }
  }

  csvContent += header.substring(-1) + "\r\n";

  data.forEach((rowArray) => {
    let row = "";
    if (isValidValue(columns)) {
      Object.keys(columns).map((Key) => {
        if (typeof rowArray[Key] !== "object") {
          row += (isValidValue(rowArray[Key]) ? rowArray[Key] : "") + ",";
        }
      });
    } else {
      Object.keys(rowArray).map((Key) => {
        if (typeof rowArray[Key] !== "object" || !isValidValue(rowArray[Key])) {
          row += (isValidValue(rowArray[Key]) ? rowArray[Key] : "") + ",";
        }
      });
    }

    csvContent += row + "\r\n";
  });

  let encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", name);
  link.click();
};

export const toSlugFormat = (value) => {
    value = value.replace(/[^1-9a-zA-Z١-٩ا-ي -]/g, ""); // remove special characters
  value = value.replace(/[^1-9a-zA-Z١-٩ا-ي]/g, "-"); // remove spaces
  let last_value = [];

  let is_duplicate = false;
  for (let i = 0; i < value.length; i++) {
    if (value.charAt(i) === "-") {
      if (!is_duplicate) {
        last_value.push(value.charAt(i));
        is_duplicate = true;
      }
    } else {
      last_value.push(value.charAt(i));
      is_duplicate = false;
    }
  }

  if (last_value[last_value.length - 1] === "-") {
    last_value.pop();
  }

  last_value = last_value.join("");

  return last_value;
};


export const disableFutureDates = (current, disable_today) => {
  let custom_date = moment().format("DD-MM-YYYY");
  if (disable_today) {
    return current && current <= moment(custom_date, "DD-MM-YYYY");
  }
  return current && current < moment(custom_date, "DD-MM-YYYY");
};
