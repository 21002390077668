import React, {useEffect, useState} from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {ConfigProvider} from "antd";
import "rsuite/dist/rsuite.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  PRIMARY_COLOR,
} from "./global/variables";
import "./App.css";
import {SlugRoute} from "./routes/SlugRoute";
import Cookies from "js-cookie";
import {isValidValue} from "./global/helper_functions";
import {VerifyEmail} from "./pages/auth/VerifyEmail";
import {RestPassword} from "./pages/auth/RestPassword";
import {Welcome} from "./pages/home/Welcome";

ConfigProvider.config({
  theme: {
    primaryColor: PRIMARY_COLOR,
    // errorColor:DANGER_COLOR_2,
  },
});


const App =()=> {
  const slug = Cookies.get("slug");
  const redirect_url = isValidValue(slug)?`/${slug}/dashboard`:"/welcome";

  return (
      <ConfigProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/welcome" exact component={Welcome}/>
            <Route path="/verify-email" exact component={VerifyEmail}/>
            <Route path="/rest-password" exact component={RestPassword}/>
            <Route path="/:slug" component={SlugRoute}/>
            <Route path="/" exact render={() => <Redirect to={redirect_url}/>}/>
          </Switch>
        </BrowserRouter>
      </ConfigProvider>
  );
}

export default App;
