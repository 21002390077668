export const BaseBackEndAPIsURL = process.env.REACT_APP_BACKEND_API_URL;

export const UsersURL = "users/";
export const UsersGetAllPermissionsURL = `${UsersURL}get-all-permissions/`;
export const UserRestPasswordURL = `${UsersURL}rest-password/`;
export const AdminSignInURL = `${UsersURL}signin/admins/`;
export const VendorSignInURL = `${UsersURL}signin/vendors/`;
export const AdminForgetPasswordURL = `${UsersURL}forget-password/admins/`;
export const VendorForgetPasswordURL = `${UsersURL}forget-password/vendors/`;
export const VerifyEmailURL = `${UsersURL}verify-email/`;

export const RulesURL = `${UsersURL}rules/`;
export const RulesListAllURL = `${RulesURL}all/`;


export const VendorsURL = `vendors/`;
export const VendorsListAllURL = `${VendorsURL}all/`;
export const VendorsDocumentURL = `${VendorsURL}documents/`;
export const VendorsBrandURL = `${VendorsURL}brands/`;
export const VendorsGetBySlugURL = `${VendorsURL}get-by-slug/`;
export const VendorsCategoriesURL = `${VendorsURL}categories/`;
export const VendorsCategoriesListAllURL = `${VendorsCategoriesURL}all/`;
export const VendorsBrandsListAllURL = `${VendorsBrandURL}all/`;

export const GeneralURL = `general/`;
export const UploadFileToServerURL = `${GeneralURL}upload-file/`;
export const AdvertisementsURL = `${GeneralURL}advertisements/`;

export const ProductsURL = `products/`;
export const ProductSizesURL = `${ProductsURL}sizes/`;
export const ProductsCategoriesURL = `${ProductsURL}categories/`;
export const ListProductsCategoriesURL = `${ProductsCategoriesURL}all/`;

export const OrdersURL = `orders/`;
export const UpdateOrderDeliveryBy = (pk) =>`${OrdersURL}${pk}/update-delivery-by/`;
export const UpdateOrderStatus = (pk) =>`${OrdersURL}${pk}/update-status/`;


export const BlogsURL = 'blogs/';
export const BlogsGalleryURL = `${BlogsURL}gallery/`;

export const PromoCodesURL = 'promo-codes/';

