import { Modal } from "antd";
import React from "react";

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      title={props.title && (<h2 className="p-0 m-0 font-size-22">{props.title}</h2>)}
      visible={props.visible}
      footer={props.footer || null}
      closable={props.closable}
      maskClosable={props.maskClosable || false}
      keyboard	={props.keyboard || false}
      destroyOnClose={props.destroyOnClose}
      className={`${props.className} custom-modal-${props.size || "lg"} p-0`}
      bodyStyle={{
          background: "white",
        paddingTop:props.paddingTop || "1rem",
        paddingRight:"1.5",
        paddingBottom:"0.5rem",
        paddingLeft: "1.5rem",
        ...(props.body_style || {})
    }}
      // modalRender={(Content)=>{
      //     return <div>{Content.props.children}</div>
      // }}
      centered={props.centered}
      transitionName="none"
    >
      {props.children}
    </Modal>
  );
};

export default CustomModal;
