import React, {useState} from "react";
import {Tabs} from "antd";
const {TabPane} = Tabs;

export const CustomTabPane = props =>{
    const default_key = useState(Math.random() + "");

    return(
        <TabPane tab={
            <span className={`${props.disabled?"text-black-50":"text-dark"}`}>
                {props.tab}
            </span>
        } key={props.key || default_key}/>

    )
}