import {PromoCodes} from "../pages/promo_codes/PromoCodes";

export const permission_data = {
  rule: {
    view: "can_view_rule",
    add: "can_add_rule",
    edit: "can_edit_rule",
    delete: "can_delete_rule",
  },
  user: {
    view: "can_view_user",
    add: "can_add_user",
    edit: "can_edit_user",
    delete: "can_delete_user",
    change_status: "can_change_user_status",
    export_user: "can_export_user",
  },
  vendor: {
    view: "can_view_vendor",
    add: "can_add_vendor",
    edit: "can_edit_vendor",
    delete: "can_delete_vendor",
    has_travels: "vendor_has_travels",
    has_experiences: "vendor_has_experiences",
  },
  vendor_document: {
    view: "can_view_vendor_document",
    add: "can_add_vendor_document",
    edit: "can_edit_vendor_document",
    delete: "can_delete_vendor_document",
  },
  vendor_brand: {
    view: "can_view_vendor_brand",
    add: "can_add_vendor_brand",
    edit: "can_edit_vendor_brand",
    delete: "can_delete_vendor_brand",
  },
  vendor_category: {
    view: "can_view_vendor_category",
    add: "can_add_vendor_category",
    edit: "can_edit_vendor_category",
    delete: "can_delete_vendor_category",
  },
  advertisement: {
    view: "can_view_advertisement",
    add: "can_add_advertisement",
    edit: "can_edit_advertisement",
    delete: "can_delete_advertisement",
  },
  online_payment: {
    view: "can_view_online_payment",
  },
  product: {
    view: "can_view_product",
    add: "can_add_product",
    edit: "can_edit_product",
    delete: "can_delete_product",
  },
  product_size: {
    view: "can_view_product_size",
    add: "can_add_product_size",
    edit: "can_edit_product_size",
    delete: "can_delete_product_size",
  },
  product_category: {
    view: "can_view_product_category",
    add: "can_add_product_category",
    edit: "can_edit_product_category",
    delete: "can_delete_product_category",
  },
  order: {
    view: "can_view_order",
    add: "can_add_order",
    edit: "can_edit_order",
    delete: "can_delete_order",
    update_status: "can_update_order_status",
    delivery_by: "can_update_delivery_by",
    can_print_recipient: "can_print_order_recipient",
    export_order: "can_export_order",
  },
  blog: {
    view: "can_view_blog",
    add: "can_add_blog",
    edit: "can_edit_blog",
    delete: "can_delete_blog",
  },
  promo_code: {
    view: "can_view_promo_code",
    add: "can_add_promo_code",
    edit: "can_edit_promo_code",
    delete: "can_delete_promo_code",
  },
};
